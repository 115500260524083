<template>
  <header>
    <div class="py-4 shadow dark:bg-gray-800">
      <div class="max-w-6xl mx-auto">
        <div class="flex items-center lg:justify-between justify-around">
          <a href="/">
            <img src="https://static.bcdcnt.net/assets/images/logo1.svg" alt="Bài ca đi cùng năm tháng" class="h-8">
          </a>
          <div class="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
            <router-link :to="{name: 'Login'}" class="py-3 px-4 mr-1 bg-green-600 px-6 py-3 rounded-md shadow text-white">
              Đăng nhập
            </router-link>
            <router-link :to="{name: 'Register'}" class="bg-green-600 px-6 py-3 rounded-md shadow text-white">
              Đăng ký
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderGuest'
}
</script>
