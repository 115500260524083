<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
    <HeaderGuest/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style>
input, input:-webkit-autofill, .bootstrap-select.btn-group button{
  background-color: #f3f4f6  !important;
  height: 44px  !important;
  box-shadow: none  !important;
}
</style>

<script>
import HeaderGuest from "./HeaderGuest";
import Footer from "./Footer";

export default {
  name: 'LayoutGuest',
  props: {
    school: Object,
    user: Object
  },
  components: {
    Footer,
    HeaderGuest
  },
}
</script>
